<template>
  <span ref="reference">
    <slot name="default" />
  </span>
  <span ref="floating" class="floating" :style="floatingStyles">
    <slot name="content" />
  </span>
</template>

<script setup lang="ts">
import { useFloating, offset } from '@floating-ui/vue'

const reference = ref() as Ref<HTMLElement|null>
const floating = ref() as Ref<HTMLElement|null>
const { floatingStyles, update } = useFloating(reference, floating, {
  placement: 'bottom',
  middleware: [offset(10)]
})

const handleClickOutside = (event) => {
  if (event.type === 'mouseup' && !floating.value?.contains(event.target) && !reference.value?.contains(event.target)) {
    hide()
  } else if (event.type === 'keydown' && event.key === 'Escape') {
    hide()
  }
}

const show = () => {
  if (floating.value) {
    floating.value.style.display = 'block'
  }
  update()
  document.addEventListener('mouseup', handleClickOutside)
  document.addEventListener('keydown', handleClickOutside)
  window.addEventListener('resize', update)
}

const hide = (reason?: string) => {
  if (floating.value) {
    floating.value.style.display = ''
  }
  document.removeEventListener('mouseup', handleClickOutside)
  document.removeEventListener('keydown', handleClickOutside)
  window.removeEventListener('resize', update)
  emit('hidden', reason)
}

defineExpose({
  show,
  hide
})

let resizeObserver
let intersectionObserver

onMounted(() => {
  let isFullyVisible = false

  intersectionObserver = new IntersectionObserver((entries) => {
    isFullyVisible = entries[0].isIntersecting
  }, { threshold: 1 })

  intersectionObserver.observe(floating.value)

  resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const fitsIntoViewport = entry.contentRect.width <= window.innerWidth && entry.contentRect.height <= window.innerHeight
      setTimeout(() => {
        if (!isFullyVisible && fitsIntoViewport) {
          entry.target.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      }, 100)
    }
  })

  resizeObserver.observe(floating.value)
})

onBeforeUnmount(() => {
  resizeObserver.unobserve(floating.value)
  intersectionObserver.unobserve(floating.value)
})

const emit = defineEmits(['hidden'])

</script>

<style lang="scss" scoped>
.floating {
  background-color: #FFFFFF;
  border-color: rgb(228 228 228 / 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  z-index: 100;
  display: none;
}
</style>
